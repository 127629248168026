import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { CamCapacitorModule, CamPwaService, PWA_CONFIG_KEY } from '@camelot/capacitor';
import { STRAPI_SERVER_CONFIG } from '@camelot/cms';
import { CamNotificationModule } from '@camelot/notification';
import { CamServerModule, GRAPHQL_SERVER_CONFIG } from '@camelot/server';
import { TENANT_CONFIG_TOKEN } from '@camelot/server';
import { CamTranslationModule, CamTranslationService, TRANSLATION_CONFIG } from '@camelot/translation';
import { CamUserModule } from '@camelot/user';
import { APPLICATION_CONFIG } from '@camelot/utils';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AzureModule } from './modules/azure/azure.module';
import { AppBaseModule } from './modules/base/base.module';
import { AppHomeModule } from './modules/base/home/home.module';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEN, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    GoogleTagManagerModule.forRoot({
      id: environment.GTM,
    }),
    AppBaseModule,
    AppHomeModule,
    AppRoutingModule,
    AzureModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    CamCapacitorModule, // only for PWA/APP
    CamNotificationModule.forRoot(),
    CamTranslationModule.forRoot(),
    CamServerModule.forRoot({ config: environment.GRAPHQL_SERVER_CONFIG }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.PWAACTIVE,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first) .
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CamUserModule.forRoot(),
  ],
  providers: [
    {
      provide: TENANT_CONFIG_TOKEN,
      useValue: { tenantId: environment.TENANT_ID },
    },
    {
      provide: PWA_CONFIG_KEY,
      useValue: { active: environment.PWAACTIVE },
    },
    {
      provide: TRANSLATION_CONFIG,
      useValue: {
        default: environment.TRANSLATION.DEFAULT,
        supportedLanguages: environment.TRANSLATION.SUPPORTEDLANGUAGES,
      },
    },
    {
      provide: 'config_local',
      useValue: { isLocal: environment.LOCAL },
    },
    {
      provide: APPLICATION_CONFIG,
      useValue: { isCustomerApplication: environment.IS_CUSTOMER_APPLICATION },
    },
    {
      provide: GRAPHQL_SERVER_CONFIG,
      useValue: { config: environment.GRAPHQL_SERVER_CONFIG },
    },
    {
      provide: STRAPI_SERVER_CONFIG,
      useValue: { config: environment.STRAPI_SERVER_CONFIG },
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: LOCALE_ID,
      deps: [CamTranslationService],
      useFactory: (TranslationService: CamTranslationService) => TranslationService.getLanguage(),
    },
    CamPwaService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
