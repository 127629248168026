import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

import { map, of } from 'rxjs';

import { CamDeviceInfoService } from '@camelot/capacitor';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Invoice } from 'src/app/services/billings/dto/invoice/invoice';

import { IPrice } from '../../../helpers/price';
import {AppInvoicesService} from "../../../../../../services/billings/invoices.service";
import {PaymentStatus} from "../../../../../../services/billings/dto/invoice/payment-status";

@Component({
  selector: 'app-card-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent extends BaseComponent implements OnInit {
  @Input()
  invoice!: Invoice;

  @Input()
  onlyPayment = false;

  @Input()
  shadow = true;

  @Input()
  displayTransferInformation = true;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click: EventEmitter<void> = new EventEmitter();

  get versionPrice(): IPrice {
    return {
      excl: this.invoice.exclVatTotal,
      incl: this.invoice.inclVatTotal,
    };
  }

  get paymentLink$() {
    return this._invoicesService.paymentLink.get$(this.invoice.id);
  }

  constructor(public deviceInfo: CamDeviceInfoService, private _invoicesService: AppInvoicesService) {
    super();
  }

  ngOnInit() {
    if(this.invoice.paymentStatus === PaymentStatus.NotPaid || this.invoice.paymentStatus === PaymentStatus.Late){
      this._fetch();
    }
  }

  public removeSpace(value: string) {
    if (!value) {
      return '';
    }
    return value.replace(/\s/g, '');
  }
  public isMobileOs$() {
    return this.deviceInfo.os$.pipe(map(value => this.deviceInfo.isMobileOs(value)));
  }
  public isWeb$() {
    return this.deviceInfo.os$.pipe(map(value => !this.deviceInfo.isMobileOs(value)));
  }

  public qrCodeData() {
    return `BCD\n002\n2\nSCT\nPCHQBEBB\n${this.invoice.beneficiaryName}\n${this.invoice.beneficiaryIban}\nEUR${this.invoice.inclVatTotal}\n \n${this.invoice.structuredCommunication}`;
  }

  private _fetch(){
    const redirectUrl = location.href;
    this.requestState.asked();
    this._invoicesService.getPaymentLink$(this.invoice.id, true, redirectUrl, redirectUrl, redirectUrl).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
