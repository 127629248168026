import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EProjectsRoute } from 'src/app/modules/projects/routing.module';
import { AppProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-home-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent extends BaseComponent {
  get inProgressProjects$() {
    return this._projectService.home.get$();
  }

  constructor(private _projectService: AppProjectsService) {
    super();
    this._fetch();
  }

  public navigateToProject(projectId: string) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.summary], {
        id: projectId,
      })
    );
  }

  public setRightText(text: string) {
    return {
      text: text,
    };
  }

  public goToProjects() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EProjectsRoute.projects, EProjectsRoute.list]));
  }

  private _fetch() {
    this.requestState.asked();
    this._projectService.fetchHomeProjects$().subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
