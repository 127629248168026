import { merge } from '@camelot/utils';

import { baseEnvironment } from './base-environment';

export const environment = merge()<typeof baseEnvironment>(baseEnvironment, {
  GRAPHQL_SERVER_CONFIG: {
    url: 'https://api-dev.merlinsoftware.be/lancelot/',
    visitor: 'https://api-dev.merlinsoftware.be/lancelot/visitor/',
  },

  STRAPI_SERVER_CONFIG: {
    url: 'https://cms-test.merlinsoftware.be/graphql',
    token:
      '26392b7e68bcaf00a7a1ab8a124957c3b3fa81d92c3cefcc89ed60734d409b8696734c7f718769243106c9617f84cc574a5219b9282b4c13f770158be82b20d391351aa0f352e3f5e5ecd7d5f326ae9baba4b0ae8232eef17f20058e4bb92de41273762111ea5f8f84fe258eb718d6e862eea5f7c8186388ce28810a39f27b69',
  },

  TENANT_ID: 999000,

  DEBUG: true,
  LOCAL: false,
  IS_CUSTOMER_APPLICATION: true,

  PWAACTIVE: true,

  AADB2C: {
    msalConfig: {
      auth: {
        clientId: 'ab59d3c4-edd5-44f7-a3d0-c585d043de9e',
      },
    },
    apiConfig: {
      scopes: [],
      uri: 'https://api-dev.merlinsoftware.be',
      visitorUri: 'https://api-dev.merlinsoftware.be/lancelot/visitor',
    },
    b2cPolicies: {
      names: {
        signUpSignIn: 'B2C_1_si',
        resetPassword: 'B2C_1_pr',
        editProfile: 'B2C_1_pe',
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://lancelotdev.b2clogin.com/lancelotdev.onmicrosoft.com/B2C_1_si',
        },
        resetPassword: {
          authority: 'https://lancelotdev.b2clogin.com/lancelotdev.onmicrosoft.com/B2C_1_pr',
        },
        editProfile: {
          authority: 'https://lancelotdev.b2clogin.com/lancelotdev.onmicrosoft.com/B2C_1_pe',
        },
      },
      authorityDomain: 'lancelotdev.b2clogin.com',
    },
    redirectUri: '/auth',
    postLogoutRedirectUri: '/login',
  },
  FEATURES: {
    youSign: true,
  },
});
