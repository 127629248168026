import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { HandleSimpleRequest } from '@camelot/server';
import { CamProjectsService, GET_MY_PROJECTS, Project, ProjectStatus } from '@camelot/services';

@Injectable({
  providedIn: 'root',
})
export class AppProjectsService extends CamProjectsService {
  public home = new HandleSimpleRequest<Project[]>();

  constructor() {
    super();
  }

  public fetchHomeProjects$() {
    return this.home.fetch(
      this._graphService
        .fetchPagedQueryList<Project>(
          GET_MY_PROJECTS({ statusList: [ProjectStatus.InProgress, ProjectStatus.Pending], take: 3 }),
          'projects',
          this._graphEndpoint.clientName
        )
        .pipe(map(data => data.items))
    );
  }
}
