import { Apollo_gql, GraphQueryPayload, graphQlTake } from '@camelot/server';

import { invoiceAddressProps } from './dto/invoice/address';
import { invoiceProps } from './dto/invoice/invoice';
import { progressStatementsProps } from './dto/invoice/progress-statements';
import { invoiceRowProps } from './dto/invoice/row';
import { quotationProps } from './dto/quotation/quotation';
import { quotationRowProps } from './dto/quotation/row';
import { quotationVersionProps } from './dto/quotation/version';
import {paymentLinkProps} from "./dto/invoice/payment-link";

/** Quotations */
export function GET_QUOTATIONS_BY_PROJECT(projectId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query Quotations($id: UUID!) {
        quotationsByProject(projectId: $id) {
          ${quotationProps.get('id')}
          ${quotationProps.get('name')}
          ${quotationProps.get('projectId')}
          ${quotationProps.get('tenantQuotationId')}
          ${quotationProps.get('status')}
          ${quotationProps.get('dueDate')}
          ${quotationProps.get('exclVatTotal')}
          ${quotationProps.get('inclVatTotal')}
          ${quotationProps.get('rowsCount')}
          ${quotationProps.get('rowsMigratedCount')}
          ${quotationProps.get('versions')} {
            ${quotationVersionProps.get('id')}
            ${quotationVersionProps.get('version')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('dueDate')}
            ${quotationVersionProps.get('exclVatTotal')}
            ${quotationVersionProps.get('inclVatTotal')}
            ${quotationVersionProps.get('isNew')}
          }
        }
      }
    `,
    variables: {
      id: projectId,
    },
  };
}

export function GET_QUOTATION(id: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query Quotation($id: UUID!) {
        quotations(where: { id: { eq: $id }}) {
          ${quotationProps.get('id')}
          ${quotationProps.get('name')}
          ${quotationProps.get('versions')} {
            ${quotationVersionProps.get('id')}
            ${quotationVersionProps.get('version')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('isNew')}
            ${quotationVersionProps.get('date')}
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  };
}

export function GET_QUOTATION_ROWS(versionId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
        query QuotationRows($id: UUID!) {
          quotationRows(versionId: $id, where: { documentIds: { any: true } }) {
            ${quotationRowProps.get('id')}
            ${quotationRowProps.get('description')}
            ${quotationRowProps.get('documentIds')}
          }
        }
    `,
    variables: {
      id: versionId,
    },
  };
}

export function GET_QUOTATION_PARENT_ROWS(versionId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationRows($id: UUID!) {
        quotationParentRows(versionId: $id) {
          ${quotationRowProps.get('id')}
          ${quotationRowProps.get('index')}
          ${quotationRowProps.get('parent')}
          ${quotationRowProps.get('hidden')}
          ${quotationRowProps.get('rowType')}
          ${quotationRowProps.get('sourceRef')}
          ${quotationRowProps.get('description')}
          ${quotationRowProps.get('itemType')}
          ${quotationRowProps.get('quantity')}
          ${quotationRowProps.get('unit')}
          ${quotationRowProps.get('unitDescription')}
          ${quotationRowProps.get('unitSellingPrice')}
          ${quotationRowProps.get('vatPercentage')}
          ${quotationRowProps.get('exclVatTotal')}
          ${quotationRowProps.get('inclVatTotal')}
          ${quotationRowProps.get('chapterExclVatTotal')}
          ${quotationRowProps.get('chapterInclVatTotal')}
          ${quotationRowProps.get('childrenRowsCount')}
          ${quotationRowProps.get('documentIds')}
        }
      }
    `,
    variables: {
      id: versionId,
    },
  };
}

export function GET_QUOTATION_ROW_CHILDREN(parentId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationRowChildren($id: UUID!) {
        quotationRowChildren(rowId: $id) {
          ${quotationRowProps.get('id')}
          ${quotationRowProps.get('index')}
          ${quotationRowProps.get('parent')}
          ${quotationRowProps.get('hidden')}
          ${quotationRowProps.get('rowType')}
          ${quotationRowProps.get('sourceRef')}
          ${quotationRowProps.get('description')}
          ${quotationRowProps.get('itemType')}
          ${quotationRowProps.get('quantity')}
          ${quotationRowProps.get('unit')}
          ${quotationRowProps.get('unitDescription')}
          ${quotationRowProps.get('unitSellingPrice')}
          ${quotationRowProps.get('vatPercentage')}
          ${quotationRowProps.get('exclVatTotal')}
          ${quotationRowProps.get('inclVatTotal')}
          ${quotationRowProps.get('chapterExclVatTotal')}
          ${quotationRowProps.get('chapterInclVatTotal')}
          ${quotationRowProps.get('childrenRowsCount')}
          ${quotationRowProps.get('documentIds')}
        }
      }
    `,
    variables: {
      id: parentId,
    },
  };
}
export function GET_QUOTATION_VERSIONS(id: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationVersionsByQuotation($id: UUID!) {
        quotationVersionsByQuotation(quotationId: $id) {
          ${quotationVersionProps.get('id')}
          ${quotationVersionProps.get('version')}
          ${quotationVersionProps.get('status')}
          ${quotationVersionProps.get('description')}
          ${quotationVersionProps.get('date')}
          ${quotationVersionProps.get('dueDate')}
          ${quotationVersionProps.get('acceptanceDate')}
          ${quotationVersionProps.get('paymentTerm')}
          ${quotationVersionProps.get('downloadUriPDF')}
          ${quotationVersionProps.get('downloadUriExcel')}
          ${quotationVersionProps.get('devisorId')}
          ${quotationVersionProps.get('rowsCount')}
          ${quotationVersionProps.get('rowsMigratedCount')}
          ${quotationVersionProps.get('fullyMigrated')}
          ${quotationVersionProps.get('exclVatTotal')}
          ${quotationVersionProps.get('inclVatTotal')}
          ${quotationVersionProps.get('discount')}
          ${quotationVersionProps.get('isNew')}
          ${quotationVersionProps.get('isSignatureRequired')}
        }
      }
    `,
    variables: {
      id: id,
    },
  };
}

export function GET_VERSIONS_BY_PROJECT(projectId: string, take?: number): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationVersions($id: UUID!) {
        quotationVersions(where: { quotation: { projectId: { eq: $id } } }, ${graphQlTake(take)}) {
          items {
            ${quotationVersionProps.get('id')}
            ${quotationVersionProps.get('version')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('acceptanceDate')}
            ${quotationVersionProps.get('exclVatTotal')}
            ${quotationVersionProps.get('inclVatTotal')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('quotation')} {
              ${quotationProps.get('id')}
              ${quotationProps.get('name')}
            }
          }

        }
      }
    `,
    variables: {
      id: projectId,
    },
  };
}

export function GET_VERSION(id: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationVersions($id: UUID!) {
        quotationVersions(where: { id: { eq: $id }}) {
          items {
            ${quotationVersionProps.get('id')}
            ${quotationVersionProps.get('version')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('description')}
            ${quotationVersionProps.get('date')}
            ${quotationVersionProps.get('dueDate')}
            ${quotationVersionProps.get('acceptanceDate')}
            ${quotationVersionProps.get('paymentTerm')}
            ${quotationVersionProps.get('downloadUriPDF')}
            ${quotationVersionProps.get('downloadUriExcel')}
            ${quotationVersionProps.get('devisorId')}
            ${quotationVersionProps.get('rowsCount')}
            ${quotationVersionProps.get('rowsMigratedCount')}
            ${quotationVersionProps.get('fullyMigrated')}
            ${quotationVersionProps.get('exclVatTotal')}
            ${quotationVersionProps.get('inclVatTotal')}
            ${quotationVersionProps.get('discount')}
            ${quotationVersionProps.get('isNew')}
            ${quotationVersionProps.get('isSignatureRequired')}
            ${quotationVersionProps.get('quotation')} {
              ${quotationProps.get('id')}
              ${quotationProps.get('name')}
            }
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  };
}
export function GET_ALL_VERSIONS(): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query QuotationVersions {
        quotationVersions {
          items {
            ${quotationVersionProps.get('id')}
            ${quotationVersionProps.get('version')}
            ${quotationVersionProps.get('status')}
            ${quotationVersionProps.get('description')}
            ${quotationVersionProps.get('date')}
            ${quotationVersionProps.get('dueDate')}
            ${quotationVersionProps.get('acceptanceDate')}
            ${quotationVersionProps.get('paymentTerm')}
            ${quotationVersionProps.get('downloadUriPDF')}
            ${quotationVersionProps.get('downloadUriExcel')}
            ${quotationVersionProps.get('devisorId')}
            ${quotationVersionProps.get('rowsCount')}
            ${quotationVersionProps.get('rowsMigratedCount')}
            ${quotationVersionProps.get('fullyMigrated')}
            ${quotationVersionProps.get('exclVatTotal')}
            ${quotationVersionProps.get('inclVatTotal')}
            ${quotationVersionProps.get('discount')}
            ${quotationVersionProps.get('isNew')}
            ${quotationVersionProps.get('isSignatureRequired')}
            ${quotationVersionProps.get('quotation')} {
              ${quotationProps.get('id')}
              ${quotationProps.get('name')}
              ${quotationProps.get('tenantQuotationId')}
            }
          }
        }
      }
    `,
    variables: {},
  };
}

/** Invoices */
export function GET_INVOICES_BY_PROJECT(projectId: string, take?: number): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query Invoices($id: UUID!) {
        invoices(where: { projectId: { eq: $id }}, ${graphQlTake(take)}) {
          items {
            ${invoiceProps.get('id')}
            ${invoiceProps.get('reference')}
            ${invoiceProps.get('inclVatTotal')}
            ${invoiceProps.get('exclVatTotal')}
            ${invoiceProps.get('paymentStatus')}
            ${invoiceProps.get('isCreditNote')}
            ${invoiceProps.get('dueDate')}
            ${invoiceProps.get('beneficiaryIban')}
            ${invoiceProps.get('beneficiaryBic')}
            ${invoiceProps.get('beneficiaryName')}
            ${invoiceProps.get('structuredCommunication')}
            ${invoiceProps.get('isNew')}
          }
        }
      }
    `,
    variables: {
      id: projectId,
    },
  };
}

export function GET_ALL_INVOICES(): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query Invoices {
        invoices {
          items {
            ${invoiceProps.get('id')}
            ${invoiceProps.get('reference')}
            ${invoiceProps.get('inclVatTotal')}
            ${invoiceProps.get('exclVatTotal')}
            ${invoiceProps.get('paymentStatus')}
            ${invoiceProps.get('isCreditNote')}
            ${invoiceProps.get('dueDate')}
            ${invoiceProps.get('beneficiaryIban')}
            ${invoiceProps.get('beneficiaryBic')}
            ${invoiceProps.get('beneficiaryName')}
            ${invoiceProps.get('structuredCommunication')}
            ${invoiceProps.get('isNew')}
          }
        }
      }
    `,
    variables: {},
  };
}

export function GET_INVOICE(id: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query Invoice($id: UUID!) {
        invoices(where: { id: { eq: $id }}) {
          items {
            ${invoiceProps.get('id')}
            ${invoiceProps.get('reference')}
            ${invoiceProps.get('description')}
            ${invoiceProps.get('downloadUriPDF')}
            ${invoiceProps.get('rowsCount')}
            ${invoiceProps.get('inclVatTotal')}
            ${invoiceProps.get('exclVatTotal')}
            ${invoiceProps.get('dueDate')}
            ${invoiceProps.get('validationDate')}
            ${invoiceProps.get('invoiceNumber')}
            ${invoiceProps.get('paymentStatus')}
            ${invoiceProps.get('contactId')}
            ${invoiceProps.get('hiddenDetails')}
            ${invoiceProps.get('isNew')}
            ${invoiceProps.get('isCreditNote')}
            ${invoiceProps.get('invoiceAddress')} {
              ${invoiceAddressProps.get('id')}
              ${invoiceAddressProps.get('country')}
              ${invoiceAddressProps.get('city')}
              ${invoiceAddressProps.get('postCode')}
              ${invoiceAddressProps.get('street')}
            }
            ${invoiceProps.get('progressStatements')} {
              ${progressStatementsProps.get('id')}
              ${progressStatementsProps.get('quotationVersionId')}
              ${progressStatementsProps.get('isFinal')}
              ${progressStatementsProps.get('isDeposit')}
            }
            ${invoiceProps.get('beneficiaryIban')}
            ${invoiceProps.get('beneficiaryBic')}
            ${invoiceProps.get('beneficiaryName')}
            ${invoiceProps.get('structuredCommunication')}
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  };
}

export function GET_INVOICE_ROWS(versionId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query InvoiceRows($id: UUID!) {
        invoiceRows(invoiceId: $id) {
          ${invoiceRowProps.get('id')}
          ${invoiceRowProps.get('index')}
          ${invoiceRowProps.get('parent')}
          ${invoiceRowProps.get('hidden')}
          ${invoiceRowProps.get('rowType')}
          ${invoiceRowProps.get('sourceRef')}
          ${invoiceRowProps.get('description')}
          ${invoiceRowProps.get('itemType')}
          ${invoiceRowProps.get('quantity')}
          ${invoiceRowProps.get('unit')}
          ${invoiceRowProps.get('unitDescription')}
          ${invoiceRowProps.get('unitSellingPrice')}
          ${invoiceRowProps.get('vatPercentage')}
          ${invoiceRowProps.get('exclVatTotal')}
          ${invoiceRowProps.get('inclVatTotal')}
          ${invoiceRowProps.get('chapterExclVatTotal')}
          ${invoiceRowProps.get('chapterInclVatTotal')}
          ${invoiceRowProps.get('childrenRowsCount')}
        }
      }
    `,
    variables: {
      id: versionId,
    },
  };
}

export function GET_INVOICE_ROW_CHILDREN(parentId: string): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query InvoiceRowChildren($id: UUID!) {
        invoiceRowChildren(rowId: $id) {
          ${invoiceRowProps.get('id')}
          ${invoiceRowProps.get('index')}
          ${invoiceRowProps.get('parent')}
          ${invoiceRowProps.get('hidden')}
          ${invoiceRowProps.get('rowType')}
          ${invoiceRowProps.get('sourceRef')}
          ${invoiceRowProps.get('description')}
          ${invoiceRowProps.get('itemType')}
          ${invoiceRowProps.get('quantity')}
          ${invoiceRowProps.get('unit')}
          ${invoiceRowProps.get('unitDescription')}
          ${invoiceRowProps.get('unitSellingPrice')}
          ${invoiceRowProps.get('vatPercentage')}
          ${invoiceRowProps.get('exclVatTotal')}
          ${invoiceRowProps.get('inclVatTotal')}
          ${invoiceRowProps.get('chapterExclVatTotal')}
          ${invoiceRowProps.get('chapterInclVatTotal')}
          ${invoiceRowProps.get('childrenRowsCount')}
        }
      }
    `,
    variables: {
      id: parentId,
    },
  };
}

export function GET_PAYMENT_LINK(id: string, qrCode: boolean): GraphQueryPayload {
  return {
    query: Apollo_gql`
      query PaymentLink($id: UUID!, $qrCode: Boolean!) {
        paymentLink(invoiceId: $id, qrCode: $qrCode) {
          ${paymentLinkProps.get('executeLink')}
          ${paymentLinkProps.get('executeQrLink')}
          ${paymentLinkProps.get('imageLink')}
        }
      }
    `,
    variables: {
      id: id,
      qrCode: qrCode,
    },
  };
}
