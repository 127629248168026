export const baseEnvironment: {
  production: boolean;
  version: string;

  /**
   * DON'T TOUCH THIS BITCH
   */
  TENANT_ID: number | null;

  DEBUG: boolean;
  DEBUG_LEVEL: number;

  LOCAL: boolean;
  IS_CUSTOMER_APPLICATION: boolean;

  GRAPHQL_SERVER_CONFIG: {
    url: string;
    local_urls: {
      user: string;
    };
  };
  STRAPI_SERVER_CONFIG: {
    url: string;
    token: string;
  };
  PENDINGREQUESTAPI: number;

  GTM: string;

  APP_NAME: string;
  MAIN_COLOR: string;
  PWAACTIVE: boolean;

  TRANSLATION: {
    DEFAULT: string;
    SUPPORTEDLANGUAGES: string[];
  };

  AADB2C: {
    msalConfig: {
      auth: {
        clientId: string;
      };
    };
    apiConfig: {
      scopes: string[];
      uri: string;
      visitorUri: string;
    };
    b2cPolicies: {
      names: {
        signUpSignIn: string;
        resetPassword: string;
        editProfile: string;
      };
      authorities: {
        signUpSignIn: {
          authority: string;
        };
        resetPassword: {
          authority: string;
        };
        editProfile: {
          authority: string;
        };
      };
      authorityDomain: string;
    };
    redirectUri: string;
    postLogoutRedirectUri: string;
  };
  FEATURES: {
    youSign: boolean;
  };
} = {
  production: false,
  version: require('../../package.json').version,

  TENANT_ID: null,
  DEBUG: false,
  LOCAL: false,
  IS_CUSTOMER_APPLICATION: true,
  DEBUG_LEVEL: 0,

  GRAPHQL_SERVER_CONFIG: {
    url: '',
    local_urls: {
      user: '',
    },
  },
  STRAPI_SERVER_CONFIG: {
    url: 'https://cms-test.merlinsoftware.be/graphql',
    token:
      '26392b7e68bcaf00a7a1ab8a124957c3b3fa81d92c3cefcc89ed60734d409b8696734c7f718769243106c9617f84cc574a5219b9282b4c13f770158be82b20d391351aa0f352e3f5e5ecd7d5f326ae9baba4b0ae8232eef17f20058e4bb92de41273762111ea5f8f84fe258eb718d6e862eea5f7c8186388ce28810a39f27b69',
  },
  PENDINGREQUESTAPI: 5,

  GTM: '',

  APP_NAME: 'Lancelot app',
  MAIN_COLOR: 'white',
  PWAACTIVE: true,

  TRANSLATION: {
    DEFAULT: 'fr',
    SUPPORTEDLANGUAGES: ['fr', 'en', 'nl', 'es'],
  },

  AADB2C: {
    msalConfig: {
      auth: {
        clientId: '',
      },
    },
    apiConfig: {
      scopes: [''],
      uri: '',
      visitorUri: '',
    },
    b2cPolicies: {
      names: {
        signUpSignIn: '',
        resetPassword: '',
        editProfile: '',
      },
      authorities: {
        signUpSignIn: {
          authority: '',
        },
        resetPassword: {
          authority: '',
        },
        editProfile: {
          authority: '',
        },
      },
      authorityDomain: '',
    },
    redirectUri: '',
    postLogoutRedirectUri: '',
  },
  FEATURES: {
    youSign: false,
  },
};
