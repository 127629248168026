import { Component, Input } from '@angular/core';

import { ProjectStatus } from '@camelot/services';
import { ColorType } from '@camelot/styles';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
})
export class ProjectStatusComponent {
  @Input()
  status!: ProjectStatus;

  @Input()
  noLabel = false;

  get color(): ColorType {
    switch (this.status) {
      case ProjectStatus.Done:
        return 'success';
      case ProjectStatus.Pending:
        return 'purple';
      case ProjectStatus.InProgress:
        return 'default';
      case ProjectStatus.Cancelled:
        return 'secondary';
      default:
        return 'purple';
    }
  }

  get label(): string {
    if (this.noLabel) {
      return '';
    }
    switch (Number(this.status)) {
      case ProjectStatus.Done:
        return 'projects.status.done';
      case ProjectStatus.Pending:
        return 'projects.status.pending';
      case ProjectStatus.InProgress:
        return 'projects.status.in-progress';
      case ProjectStatus.Cancelled:
        return 'projects.status.cancelled';
      default:
        return 'projects.status.unknown';
    }
  }
}
