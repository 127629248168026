import { Component, Input } from '@angular/core';

import { ColorType } from '@camelot/styles';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { PaymentStatus } from 'src/app/services/billings/dto/invoice/payment-status';

@Component({
  selector: 'app-billings-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})

/**
 * @deprecated
 * USe the one in camelot
 */

export class PaymentStatusComponent extends BaseComponent {
  @Input()
  status!: PaymentStatus;

  @Input()
  noLabel = false;

  @Input()
  isCreditNote = false;

  constructor() {
    super();
  }

  public getType(): ColorType {
    switch (this.status) {
      case PaymentStatus.NotPaid:
        return !this.isCreditNote ? 'default' : 'purple';
      case PaymentStatus.Paid:
        return 'success';
      case PaymentStatus.PartiallyPaid:
        return 'warning';
      case PaymentStatus.Late:
        return 'alert';
      default:
        return 'default';
    }
  }

  public getTranslate() {
    if (this.noLabel) {
      return '';
    }
    if (this.status == PaymentStatus.NotPaid && this.isCreditNote) {
      return `billings.payment-status.to-payback`;
    }
    return `billings.payment-status.${PaymentStatus[this.status].toLocaleLowerCase()}`;
  }
}
