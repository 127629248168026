<cam-card [noContent]="true" [fullHeight]="true" (click)="this.click.emit()">
  <cam-card-header>
    <cam-card-tag>
      <div class="flex-row align-center space-between">
        <div class="name">{{ this.project.tenantInformation.tenantName }}</div>
        <div class="project">{{ this.project.tenantInformation.projectId }}</div>
      </div>
    </cam-card-tag>
    <cam-card-title>
      <cam-title [level]="4" [isBold]="true">
        {{ this.project.name }}
      </cam-title>
      <app-project-status [status]="this.project.status"></app-project-status>
    </cam-card-title>
  </cam-card-header>

  <cam-card-content>
    <div class="address" *ngIf="this.project.projectAddress">
      <cam-font-icon class="mr-space-xs" name="pin" type="sm"></cam-font-icon>
      <div class="my-a">
        {{ this.project.projectAddress.street }}, {{ this.project.projectAddress.postCode }} {{ this.project.projectAddress.city }}
      </div>
    </div>
  </cam-card-content>
</cam-card>
