import { Apollo_gql, GraphMutationPayload } from '@camelot/server';

import { invoiceProps } from './dto/invoice/invoice';
import { RefusalInfos } from './dto/mutation/RefusalInfos';
import { quotationVersionProps } from './dto/quotation/version';

/**
 * @deprecated
 * USe the one in camelot
 */
export function READ_QUOTATION_VERSION(id: string): GraphMutationPayload {
  return {
    mutation: Apollo_gql`
      mutation QuotationVersionRead($id: UUID!) {
        quotationVersionRead(quotationVersionId: $id) {
          ${quotationVersionProps.get('id')}
          ${quotationVersionProps.get('isNew')}
        }
      }
    `,
    variables: {
      id,
    },
  };
}

export function READ_INVOICE(id: string): GraphMutationPayload {
  return {
    mutation: Apollo_gql`
      mutation InvoiceRead($id: UUID!) {
        invoiceRead(invoiceId: $id) {
            ${invoiceProps.get('id')}
            ${invoiceProps.get('isNew')}
        }
      }
    `,
    variables: {
      id,
    },
  };
}

export function QUOTATION_VERSION_REFUSED(
  quotationVersionId: string,
  refusalInfos: RefusalInfos
): GraphMutationPayload {
  return {
    mutation: Apollo_gql`
        mutation QuotationVersionRefused($quotationVersionId: UUID!, $reason: Int!, $content: String!) {
            quotationVersionRefused(quotationVersionId: $quotationVersionId, refusalInfos: { reason: $reason, comment: $content }) {
                ${quotationVersionProps.get('id')}
                ${quotationVersionProps.get('status')}
            }
        }
      `,
    variables: {
      quotationVersionId,
      reason: refusalInfos.reason,
      content: refusalInfos.content,
    },
  };
}

export function QUOTATION_VERSION_SIGNED(quotationVersionId: string): GraphMutationPayload {
  return {
    mutation: Apollo_gql`
        mutation QuotationVersionRefused($quotationVersionId: UUID!) {
            quotationVersionSigned(quotationVersionId: $quotationVersionId) {
                ${quotationVersionProps.get('id')}
                ${quotationVersionProps.get('status')}
            }
        }
      `,
    variables: {
      quotationVersionId,
    },
  };
}
