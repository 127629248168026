import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { downloadFile } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Invoice } from 'src/app/services/billings/dto/invoice/invoice';
import { InvoiceRow } from 'src/app/services/billings/dto/invoice/row';
import { AppInvoicesService } from 'src/app/services/billings/invoices.service';

import { PaymentStatus } from '../../../../services/billings/dto/invoice/payment-status';
import { BillingDetailService } from '../detail/detail.component';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class InvoiceItemComponent extends BaseComponent {
  @Input()
  item!: Invoice;

  PaymentStatus = PaymentStatus;

  public readonly service: BillingDetailService = {
    get$: (id: string) => this._invoiceService.invoiceRows.get$(id),
    fetchRow$: (id: string) => this._invoiceService.fetchInvoiceRows$(id),
    fetchRowChildren$: (id: string, parent: InvoiceRow) => this._invoiceService.fetchInvoiceRowChildren$(id, parent),
  };
  constructor(private _invoiceService: AppInvoicesService) {
    super();
  }
  public downloadFile(file: string) {
    downloadFile(file);
  }
}
