import { Injectable } from '@angular/core';

import {filter, map, Observable, tap} from 'rxjs';

import { CamBaseService, GraphEndpoint, HandleComplexRequest, HandleSimpleRequest } from '@camelot/server';
import { isNonNullable } from '@camelot/utils';

import { Invoice } from './dto/invoice/invoice';
import { InvoiceRow } from './dto/invoice/row';
import { PaymentLink } from "./dto/invoice/payment-link";
import {
  GET_ALL_INVOICES,
  GET_INVOICE,
  GET_INVOICES_BY_PROJECT,
  GET_INVOICE_ROWS,
  GET_INVOICE_ROW_CHILDREN,
  GET_PAYMENT_LINK,
} from './queries';
import { READ_INVOICE } from './userMutations';

const graphEndpoint: GraphEndpoint = {
  clientName: 'invoiceService',
  endpoint: 'invoice',
};

@Injectable({
  providedIn: 'root',
})
export class AppInvoicesService extends CamBaseService {
  public invoicesByProject = new HandleComplexRequest<Invoice[]>();
  public invoices = new HandleSimpleRequest<Invoice[]>();

  public dashboard = new HandleComplexRequest<Invoice[]>();

  public invoice = new HandleComplexRequest<Invoice>();

  public invoiceRows = new HandleComplexRequest<InvoiceRow[]>();

  public paymentLink = new HandleComplexRequest<PaymentLink | null>();

  constructor() {
    super();

    super.registerRoutes({ graphEndpoint: graphEndpoint });
  }

  public fetchInvoicesDashboard$(projectId: string) {
    return this.dashboard.fetch(
      projectId,
      this._graphService
        .fetchPagedQueryList<Invoice>(GET_INVOICES_BY_PROJECT(projectId, 5), 'invoices', graphEndpoint.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchAllInvoices$() {
    return this.invoices.fetch(
      this._graphService
        .fetchPagedQueryList<Invoice>(GET_ALL_INVOICES(), 'invoices', graphEndpoint.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchInvoicesByProject$(projectId: string) {
    return this.invoicesByProject.fetch(
      projectId,
      this._graphService
        .fetchPagedQueryList<Invoice>(GET_INVOICES_BY_PROJECT(projectId), 'invoices', graphEndpoint.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchInvoice$(id: string) {
    return this.invoice.fetch(
      id,
      this._graphService.fetchPagedQueryList<Invoice>(GET_INVOICE(id), 'invoices', graphEndpoint.clientName).pipe(
        map(data => (data.items && data.items.length === 1 ? data.items[0] : null)),
        filter(isNonNullable),
        tap(invoice => this.isRead$(invoice.id).subscribe())
      )
    );
  }

  public fetchInvoiceRows$(id: string) {
    return this.invoiceRows.fetch(
      id,
      this._graphService.fetchQueryList<InvoiceRow>(GET_INVOICE_ROWS(id), 'invoiceRows', graphEndpoint.clientName).pipe(
        map(list =>
          list.map(item => ({
            ...item,
            ...{ label: item.index.toString() },
          }))
        )
      )
    );
  }

  public fetchInvoiceRowChildren$(id: string, parent?: InvoiceRow) {
    return this.invoiceRows.fetch(
      id,
      this._graphService
        .fetchQueryList<InvoiceRow>(GET_INVOICE_ROW_CHILDREN(id), 'invoiceRowChildren', graphEndpoint.clientName)
        .pipe(
          map(list =>
            list.map(item => ({
              ...item,
              ...{ label: `${parent?.label}.${item.index}` },
            }))
          )
        )
        .pipe(map(invoice => invoice))
    );
  }

  public isRead$(id: string) {
    return this._graphService.mutate<boolean>(READ_INVOICE(id), 'invoiceRead', graphEndpoint.clientName);
  }

  public getPaymentLink$(id: string, qrCode: boolean, confirmationUrl: string, cancelUrl: string, errorUrl: string): Observable<PaymentLink | null>{
    return this.paymentLink.fetch(
      id,
      this._graphService.fetchQuery<PaymentLink | null>(GET_PAYMENT_LINK(id, qrCode, confirmationUrl, cancelUrl, errorUrl), 'paymentLink', graphEndpoint.clientName)
    )
  }
}
