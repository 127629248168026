<ng-container *ngIf="(this.breakpoints.isMobile$ | async) === false">
  <cam-main-menu *ngIf="this.userMenu" class="menu" [menuMain]="this.menu" [menuUser]="this.userMenu"></cam-main-menu>
</ng-container>
<ng-container *ngIf="this.breakpoints.isMobile$ | async">
  <cam-main-menu
    *ngIf="this.userMenu"
    class="menu"
    [menuMain]="this.mergeMenu(this.menu, this.userMenu)"
  ></cam-main-menu>
</ng-container>

<ng-template #notificationCounterTemplate>
  <cam-notification-bullet
    [filters]="{
    isNew: true,
  }"
  ></cam-notification-bullet>
</ng-template>

<ng-template #accountTemplate>
  <div class="my-account">
    <cam-my-account
      (navigateEvent)="this.toMyProfile()"
      (navigateEditEvent)="this.goToEditProfile()"
      [infosMenu]="this.infoMenu"
      [appVersion]="this.appVersion"
    ></cam-my-account>
  </div>
</ng-template>

<ng-template #notificationTemplate>
  <div class="notificaitons">
    <div class="m-space-sm">
      <cam-title [level]="3" [isBold]="true">{{ 'base.notification.title' | translate }}</cam-title>
    </div>
    <div class="flex-column g-space-sm">
      <app-notification-list
        type="new"
        title="notifications.menu.new.title"
        ctaTitle="notifications.menu.old.cta"
        (cta)="this.goToNotification()"
      ></app-notification-list>
      <!-- 
      <app-notification-list
        type="old"
        title="notifications.menu.old.title"
        ctaTitle="notifications.menu.old.cta"
        (cta)="this.goToNotification()"
        [take]="5"
        iconName=""
      ></app-notification-list> -->
    </div>
    <!-- <div>
      <div class="m-space-sm space-between align-center">
        <cam-title [level]="4">
          {{ 'notifications.menu.old.title' | translate }}
        </cam-title>
        <cam-button size="small" (action)="this.goToNotification()">
          {{ 'notifications.menu.old.cta' | translate }}
        </cam-button>
      </div>

      <app-notification-list type="old" title="" [take]="5"></app-notification-list>
    </div> -->
  </div>
</ng-template>
