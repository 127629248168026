import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AuthModule } from '@auth0/auth0-angular';
import { MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { CAM_AUTH_TOKEN, CamUserService } from '@camelot/user';

import { environment } from 'src/environments/environment';

import { AzureInterceptor } from './interceptor/azure-interceptor';
import { AzureAuthService } from './services/azure-auth.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.AADB2C.msalConfig.auth.clientId,
      authority: environment.AADB2C.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.AADB2C.redirectUri,
      postLogoutRedirectUri: environment.AADB2C.postLogoutRedirectUri,
      knownAuthorities: [environment.AADB2C.b2cPolicies.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      // allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
      windowHashTimeout: 1000,
      iframeHashTimeout: 1000,
      redirectNavigationTimeout: 1000,
    },
  });
}

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule, AuthModule.forRoot()],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AzureModule {
  static forRoot(): ModuleWithProviders<AzureModule> {
    return {
      ngModule: AzureModule,
      providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AzureInterceptor,
          multi: true,
        },
        CamUserService,
        MsalBroadcastService,
        MsalGuard,
        MsalService,
        { provide: CAM_AUTH_TOKEN, useFactory: () => new AzureAuthService() },
      ],
    };
  }
}
