import {GraphSchema} from "@camelot/server";

export interface PaymentLink{
  executeLink: string;
  executeQrLink: string;
  imageLink: string;
}

export const paymentLinkProps = new GraphSchema<PaymentLink>([
  'executeLink',
  'executeQrLink',
  'imageLink'
]);
